import React from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../assets/svg/Quotes";
import i18n from '../../i18n'; // Make sure you import the i18n instance

export default function TestimonialBox({ text, author }) {
  const directionClass = i18n.language === 'en' ? 'ltr-i18n' : '';

  return (
    <Wrapper className="darkBg radius8 flexNullCenter flexColumn">
      <QuoteWrapper>
        <QuoteIcon />
      </QuoteWrapper>
      <div className={`quotes ${directionClass}`}>
        <p className="whiteColor font13" style={{ paddingBottom: "30px" }}>
          {text}
        </p>
        <p className="orangeColor font13" style={{alignSelf: 'flex-end'}}>
          <em>{author}</em>
        </p>
      </div>

    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  margin-top: 30px;
`;
const QuoteWrapper = styled.div`
  position: relative;
  top: -40px;
`;