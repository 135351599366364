export const categoryData = [
  {
    label: "زندگی سالم",
    name: "زندگی سالم",
    value: "life",
  },
  {
    label: "تغذیه و رژیم غذایی",
    name: "تغذیه و رژیم غذایی",
    value: "food",
  },
  {
    label: "ورزش و تناسب اندام",
    name: "ورزش و تناسب اندام",
    value: "sport",
  },
  {
    label: "سلامت روان",
    name: "سلامت روان",
    value: "peace",
  },
  {
    label: "سلامت خانواده",
    name: "سلامت خانواده",
    value: "family",
  },
  {
    label: "اخبار پزشکی",
    name: "اخبار پزشکی",
    value: "news",
  },
  {
    label: "خدمات بهداشتی و درمانی",
    name: "خدمات بهداشتی و درمانی",
    value: "health",
  },
];
