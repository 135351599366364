// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios from 'axios';

const getGeolocationData = async () => {
  try {
    const response = await axios.get('https://ipinfo.io?token=616a6c58f91110');
    const country = response.data.country;
    return country;
  } catch (error) {
    console.error('Error fetching geolocation data:', error);
    return null;
  }
};
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fa',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
  });

  const setLanguageByIP = async () => {
    const country = await getGeolocationData();
    // console.log(country)
    // const language = country != 'IR' && country != null ? 'en' : 'fa';
    const language = 'fa';
    i18n.changeLanguage(language);
  };
  
  setLanguageByIP();

export default i18n;
