import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.border ? "transparent" : "#7620ff")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  font-size: 1rem; /* Default font size (16px) for larger screens */

  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#580cd2")};
    border: 1px solid #7620ff;
    color: ${(props) => (props.border ? "#7620ff" : "#fff")};
  }

  /* Medium screens (max-width: 859px) */
  @media only screen and (max-width: 859px) {
    font-size: 0.9rem; /* Slightly smaller font size (14.4px) */
  }

  /* Smaller screens (max-width: 720px) */
  @media only screen and (max-width: 720px) {
    font-size: 0.85rem; /* Smaller font size (13.6px) */
  }

  /* Even smaller screens (max-width: 600px) */
  @media only screen and (max-width: 600px) {
    font-size: 0.8rem; /* Smaller font size (12.8px) */
  }

  /* Smallest screens (max-width: 480px) */
  @media only screen and (max-width: 480px) {
    font-size: 0.75rem; /* Smallest font size (12px) */
  }
`;


