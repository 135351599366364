import React from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import { Icon, divIcon, point } from "leaflet";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import Barat from "../../images/Enhanced_Barat.png";
import Khalili from "../../images/Ms-Khalili.png";
import Ebrahimi from "../../images/Mr-Ebrahimi.png";
import Koodak from "../../images/Mr-Koodak.png";
import Rajab from "../../images/Mr-Rajab.png";
import Kianian from "../../images/Ms-Kianian.png";
// import Koodakpoor from "../../images/Mr-Koodak1.png";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'; // Make sure you import the i18n instance
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie, faCogs, faBriefcase, faLightbulb, faBoxOpen  } from '@fortawesome/free-solid-svg-icons'

export default function Projects() {

    const { t } = useTranslation();
    const directionClass = i18n.language === 'en' ? 'ltr-i18n' : '';
    const customIcon = new Icon({
      iconUrl: require("../../images/A_Logo.png"),
      iconSize: [38, 38] // size of the icon
    });

  // custom cluster icon
    const createClusterCustomIcon = function (cluster) {
      return new divIcon({
        html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
        className: "custom-marker-cluster",
        iconSize: point(33, 33, true)
      });
    };

    // markers
    const markers = [
      {
        geocode: [32.653293, 51.663406],
        popUp: "Isfahan Branch"
      },
      {
        geocode: [35.70480870116816, 51.35274447420501],
        popUp: "Tehran branch"
      },
    ];
    
  return (
    <Wrapper>
      <div id="projects" className="whiteBg">
        <div className={`about-us-div container ${directionClass}`}>
          <HeaderInfo>
            <p className="font40 extraBold">{t('ProjectsTeam')}</p>
            <p className="font13">
              {t('ProjectsTeamDescFirst')}
              <br />
              {t('ProjectsTeamDescSecond')}
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="custom-box">
              <ProjectBox
                img={Barat}
                title={t('ProjectsHajBarat')}
                alternative={"person1"}
                text="مدیر ارشد اجرایی"
                icon={faUserTie}
                color="#4CAF50"
              />
            </div>
            <div className="custom-box">
              <ProjectBox
                img={Khalili}
                title={t('ProjectsKhalili')}
                alternative={"person2"}
                text="مدیر ارشد محصول"
                icon={faBoxOpen}
                color="#FF9800"
              />
            </div>
            <div className="custom-box">
              <ProjectBox
                img={Ebrahimi}
                title={t('ProjectsEbrahimi')}
                alternative={"person3"}
                text="مدیر ارشد نوآوری"
                icon={faLightbulb}
                color="#FFEB3B"
              />
            </div>
            <div className="custom-box">
              <ProjectBox
                img={Kianian}
                title={t('ProjectsKianian')}
                alternative={"person4"}
                text="مدیر ارشد کسب و کار"
                icon={faBriefcase}
                color="#2196F3"
              />
            </div>
            <div className="custom-box">
              <ProjectBox
                img={Koodak}
                title={t('ProjectsKoodakpoor')}
                alternative={"person6"}
                text="مدیر ارشد تکنولوژی"
                icon={faCogs}
                color="#9C27B0"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="locations" className="lightBg">
        <div className="container-map-location">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                <MapContainer center={[34.072255, 51.621091]} zoom={5} style={{ height: '350px', width: '100%' }}>
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MarkerClusterGroup
                      chunkedLoading
                      iconCreateFunction={createClusterCustomIcon}
                    >
                      {markers.map((marker) => (
                        <Marker position={marker.geocode} icon={customIcon}>
                          <Popup>{marker.popUp}</Popup>
                        </Marker>
                      ))}
                    </MarkerClusterGroup>
                  </MapContainer>
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <div className={`locations-div  ${directionClass}`}>
              <h4 className="font15 semiBold">{t('ProjectsAreasTitle')}</h4>
              <h2 className="font40 extraBold">{t('ProjectsAreasName')}</h2>
              <p className="header-text font12"> 
                {t('ProjectsAreasDesc')}
              </p>
              </div>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 20px 0;
  margin: 20px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 30px 0 20px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  @media (max-width: 860px) {
    order: 1;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
