import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import ConfettiExplosion from 'react-confetti-explosion';
import "./Confetti.css";

const Confetti = ({ handleClose, handleShowLogin }) => {
  return (
    <div className="modal-add-event-con display-block">
      <section className="modal-main-add-event-con">
        <div className="xmark-font-awsome-right">
          <button className="submit-filter-but" onClick={handleClose}>
            <FontAwesomeIcon className="fontawesome-icon fa-2xl" icon={faXmark} color="#DE3163"/> 
          </button>     
        </div>
        <div className="success-message-sign">
            <p className="succ-sign-p">ثبت نام شما با موفقیت انجام شد!</p>
            <button onClick={handleShowLogin} className="submit-changes">
              ورود به حساب
            </button>
          </div>
        <div className="confetti-content" style={{ position: "relative", zIndex: 200702 }}>
          <ConfettiExplosion 
            particleCount={200}
            duration={2000}
            width={700}
            force={0.8}
          />
        </div>
      </section>
    </div>
  );
};

export default Confetti;
