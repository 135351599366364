import React, { useState } from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
// import HeaderImage from "../../assets/img/header-img.png";
import HeaderImage from "../../images/header.webp";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'; // Make sure you import the i18n instance
import Signin from "../../screens/Signin";
import Cookies from "js-cookie";
import Signup from "../../screens/Signup";

export default function Header() {
  const { t } = useTranslation();

  const directionClass = i18n.language === 'en' ? 'ltr-i18n' : '';

  const [showSignin, setShowSignin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  const handleShowSignin = () => {
    setShowSignin(true);
  };

  const handleHideSignin = () => {
      setShowSignin(false);
  };

  const handleShowSignup = () => {
    setShowSignin(false);
    setShowSignup(true);
};

  const handleHideSignup = () => {
    setShowSignup(false);
  };

  return (
    <>
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div className={`header-div ${directionClass}`}>
          <h1 className="extraBold font60">{t('HeaderSlogan')}</h1>
          <HeaderP className="header-text font13 semiBold">
            {t('HeaderDesc')}
          </HeaderP>
          <BtnWrapper>
           {!Cookies.get("token") && <div onClick={handleShowSignin}>
              <FullButton title={t('Start')} />
            </div>}
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" width={507} height={507} src={HeaderImage} alt="header" style={{zIndex: 9}} loading="lazy"/>
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div className="header-div">
              <p className="font15 whiteColor">
                <em>
                {t('HeaderGandiText')}
                </em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>{t('HeaderGandiName')}</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        {/* <GreyDiv className="lightBg"></GreyDiv> */}
      </RightSide>
      {showSignin && <Signin handleClose={handleHideSignin} handleShowSignup={handleShowSignup}/>}
    </Wrapper>
    {showSignup && <Signup handleClose={handleHideSignup}/>}

    </>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  text-align: justify;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: justify;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


