import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function ProjectBox({ img, title, text, action, alternative, icon, color  }) {
  return (
    <Wrapper>
      <ImgBtn className="aniamte-about-us pointer" onClick={action ? () => action() : null}>
        <img className="radius-about-us" src={img} alt={alternative}></img>
      </ImgBtn>
      <h3 className="font20-title extraBold-title">{title}</h3>
      <div className="text-with-icon">
        {icon && <FontAwesomeIcon icon={icon} style={{ color: color}} />} {/* Add the icon */}
        <span className="font13">{text}</span> {/* Display text beside the icon */}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;