import React, { useState, useEffect } from 'react';
import './EditReminderForm.css'; // Assuming a similar CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faArrowUp91, faBalanceScale, faCalendarAlt, faCapsules, faComment, faImage, faListNumeric, faPenToSquare, faPills, faPlus, faPrescriptionBottleAlt, faStopwatch, faSyringe, faTablet, faTablets, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { AiOutlineFileText } from 'react-icons/ai';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import jalaali from 'jalaali-js';
import { API } from '../backend';
import Cookies from 'js-cookie';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs from 'dayjs'; 

const EditReminderForm = ({ reminder, handleClose, handleUpdate }) => {
    const [formData, setFormData] = useState({ ...reminder });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormData({ ...reminder }); // Prefill the form with reminder data
    }, [reminder]);

    const convertToDayjs = (dateObj) => {
      if (!dateObj) return null;
      const { year, month, day, hour = 0, minute = 0 } = dateObj;
      return dayjs(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`);
    };

    const convertJalaliToGregorianWithTime = ({ year, month, day, hour, minute }) => {
      console.log(year, month, day, hour, minute )
      if (!hour) return `${year}-${month}-${day}T00:00:00`;
  
      const { gy, gm, gd } = jalaali.toGregorian(year, month, day);
      return `${gy}-${String(gm).padStart(2, '0')}-${String(gd).padStart(2, '0')}T${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:00`;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
    
        // Convert Jalali dates to Gregorian if present
        const formattedData = { ...formData };
    
        const convertedFirstTime = formData.first_time_of_intake
          ? convertJalaliToGregorianWithTime(formData.first_time_of_intake)
          : null;
        const convertedStoppedDate = formData.stopped_by_datetime
          ? convertJalaliToGregorianWithTime(formData.stopped_by_datetime)
          : null;
    
        // Update formData with the converted dates
        if (convertedFirstTime) {
            formattedData.first_time_of_intake = convertedFirstTime;
        }
        if (convertedStoppedDate) {
            formattedData.stopped_by_datetime = convertedStoppedDate;
        }
    
        try {
            const response = await fetch(`${API}api/medication/${reminder.id}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Token ' + Cookies.get('token'), 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formattedData),
            });
    
            if (response.ok) {
                const updatedReminder = await response.json();
                handleUpdate(updatedReminder); // Call function to update the state in parent component
                handleClose(); // Close the modal
            } else {
                const errorData = await response.json();
                setErrors(errorData); // Handle errors
            }
        } catch (error) {
            console.error('Error updating reminder:', error);
        }
    };

    
    return (
        <div className="modal">
            <div className="modal-content">
                {/* <span className="close" onClick={handleClose}>&times;</span> */}
                <div className='xmark-font-awsome-right'>
                    <button className='submit-filter-but'>
                      <FontAwesomeIcon onClick={handleClose} className="fontawesome-icon fa-2xl" icon={ faXmark } color=""/> 
                  </button>     
                </div>

                <form onSubmit={handleFormSubmit} className="medication-form">
                <div className="form-group">
                                <label>نام دارو <FontAwesomeIcon className="fa-sm" icon={ faPills } color="red"/></label>
                                <input 
                                  type="text" 
                                  value={formData.medicine_name} 
                                  onChange={(e) => setFormData({ ...formData, medicine_name: e.target.value })} 
                                />
                                {errors.medicine_name && <p className="error">{errors.medicine_name}</p>}
                              </div>

                              <div className="form-group">
                                <label>روش مصرف <FontAwesomeIcon className="fa-sm" icon={ faSyringe } color="red"/></label>
                                <select 
                                  value={formData.route_of_administration} 
                                  onChange={(e) => setFormData({ ...formData, route_of_administration: e.target.value })}>
    
                                  <option value="">انتخاب کنید</option>
                                  <option value="oral">خوراکی</option>
                                  <option value="parentral/im">تزریق عضلانی</option>
                                  <option value="parentral/sc">تزریق زیرجلدی</option>
                                  <option value="parentral/iv">تزریق وریدی</option>
                                </select>
                                {errors.route_of_administration && <p className="error">{errors.route_of_administration}</p>}
                              </div>

                              <div className="form-group">
                                <label>شکل دارو <FontAwesomeIcon className="fa-sm" icon={ faPrescriptionBottleAlt } color="red"/></label>
                                <select 
                                  value={formData.dosage_form} 
                                  onChange={(e) => setFormData({ ...formData, dosage_form: e.target.value })}>
                                  <option value="">انتخاب کنید</option>
                                  <option value="tablet">قرص</option>
                                  <option value="capsule">کپسول</option>
                                  <option value="syrup">شربت</option>
                                  <option value="injectable">تزریقی</option>
                                </select>
                                {errors.dosage_form && <p className="error">{errors.dosage_form}</p>}
                              </div>

                              <div className="form-group">
                                <label>واحد اندازه گیری دوز <FontAwesomeIcon className="fa-sm" icon={ faBalanceScale } color="red"/></label>
                                <select 
                                  value={formData.dosage_unit_of_measure} 
                                  onChange={(e) => setFormData({ ...formData, dosage_unit_of_measure: e.target.value })}   
                                >
                                  <option selected disabled value="">لطفاً انتخاب کنید...</option>
                                  <option value="tablet">قرص</option>
                                  <option value="capsule">کپسول</option>
                                  <option value="gravimetric/mg">میلی‌گرم/mg</option>
                                  <option value="gravimetric/iu">واحد بین‌المللی/iu</option>
                                  <option value="volumetric/ml">میلی‌لیتر/ml</option>
                                </select>
                                {errors.dosage_unit_of_measure && <p className="error">{errors.dosage_unit_of_measure}</p>}
                              </div>

                              <div className="form-group">
                                <label>بازه زمانی <FontAwesomeIcon className="fa-sm" icon={ faStopwatch } color="red"/></label>
                                <select 
                                  value={formData.periodic_interval} 
                                  onChange={(e) => setFormData({ ...formData, periodic_interval: e.target.value })} 
                                >
                                  <option selected disabled value="">لطفاً انتخاب کنید...</option>
                                  <option value="daily">روزانه</option>
                                  <option value="weekly">هفتگی</option>
                                  <option value="monthly">ماهانه</option>
                                </select>
                                {errors.periodic_interval && <p className="error">{errors.periodic_interval}</p>}
                              </div>

                              <div className="form-group">
                                <label>تعداد دفعات مصرف در روز <FontAwesomeIcon className="fa-sm" icon={ faArrowUp91 } color="red"/></label>
                                <input 
                                  type="number" pattern="\d*" placeholder="e.g. 1" step="1" min="1"
                                  value={formData.dosage_frequency} 
                                  onChange={(e) => setFormData({ ...formData, dosage_frequency: e.target.value })} 
                                />
                                {errors.dosage_frequency && <p className="error">{errors.dosage_frequency}</p>}
                              </div>

                              <div className="form-group">
                                <label>مقدار واحدهای دوز در هر بار <FontAwesomeIcon className="fa-sm" icon={ faListNumeric } color="red"/></label>
                                <input 
                                  type="number" pattern="\d*" placeholder="e.g. 2.5" min="0.1" step="0.1" 
                                  value={formData.dosage_quantity_of_units_per_time} 
                                  onChange={(e) => setFormData({ ...formData, dosage_quantity_of_units_per_time: e.target.value })} 
                                />
                                {errors.dosage_quantity_of_units_per_time && <p className="error">{errors.dosage_quantity_of_units_per_time}</p>}
                              </div>

                              <div className="form-group">
                                <label>تاریخ شروع <FontAwesomeIcon className="fa-sm" icon={ faCalendarAlt } color="red"/></label>
                              
                                <DatePicker
                                  value={formData.first_time_of_intake} 
                                  onChange={(selectedDate) => {
                                    setFormData({ ...formData, first_time_of_intake: selectedDate });
                                  }}
                                  locale="fa"
                                  inputPlaceholder="تاریخ را انتخاب کنید"
                                  shouldHighlightWeekends
                                  calendarClassName="date-picker-calendar"
                                  wrapperClassName="date-picker-wrapper"
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <MobileTimePicker
                                    // label="زمان شروع"
                                    // value={formData.intake_time}
                                    value={convertToDayjs(formData.first_time_of_intake)}
                                    onChange={(newTime) => {
                                      if (newTime) {
                                        // Update the hour and minute of the existing first_time_of_intake object
                                        setFormData({
                                          ...formData,
                                          first_time_of_intake: {
                                            ...formData.first_time_of_intake,
                                            hour: newTime.hour(),
                                            minute: newTime.minute(),
                                          },
                                        });
                                      }
                                    }}
                                    // onChange={(newTime) => setFormData({ ...formData, intake_time: newTime })}
                                    renderInput={(params) => <TextField {...params} />}
                                    ampm={false}
                                  />
                                </LocalizationProvider>
                                {errors.first_time_of_intake && <p className="error">{errors.first_time_of_intake}</p>}
                              </div>

                              <div className="form-group">
                                <label>مدت مصرف <FontAwesomeIcon className="fa-sm" icon={ faTablets } color="red"/></label>
                                <select 
                                  value={formData.is_chronic_or_acute} 
                                  onChange={(e) => setFormData({ ...formData, is_chronic_or_acute: e.target.value })}>
                                  <option value="true">بلند مدت</option>
                                  <option value="false">کوتاه مدت</option>
                                </select>
                                {errors.is_chronic_or_acute && <p className="error">{errors.is_chronic_or_acute}</p>}
                              </div>

                              <div className="form-group">
                                <label>تاریخ توقف <FontAwesomeIcon className="fa-sm" icon={ faCalendarAlt } color="red"/></label>
                                <DatePicker
                                  value={formData.stopped_by_datetime} 
                                  onChange={(selectedDate) => {
                                    setFormData({ ...formData, stopped_by_datetime: selectedDate });
                                  }}
                                  locale="fa"
                                  inputPlaceholder="تاریخ را انتخاب کنید"
                                  shouldHighlightWeekends
                                  calendarClassName="date-picker-calendar"
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <MobileTimePicker
                                    // label="زمان شروع"
                                    // value={formData.stopped_time}
                                    value={convertToDayjs(formData.stopped_by_datetime)}
                                    // onChange={(newTime) => setFormData({ ...formData, stopped_time: newTime })}
                                    onChange={(newTime) => {
                                      if (newTime) {
                                        // Update the hour and minute of the existing first_time_of_intake object
                                        setFormData({
                                          ...formData,
                                          stopped_by_datetime: {
                                            ...formData.stopped_by_datetime,
                                            hour: newTime.hour(),
                                            minute: newTime.minute(),
                                          },
                                        });
                                      }
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    ampm={false}
                                  />
                                </LocalizationProvider>
                                {errors.stopped_by_datetime && <p className="error">{errors.stopped_by_datetime}</p>}
                              </div>

                              <div className="form-group">
                                <label>توضیحات <FontAwesomeIcon className="fa-sm" icon={ faComment } color="red"/></label>
                                <textarea 
                                  value={formData.regimen_note} 
                                  onChange={(e) => setFormData({ ...formData, regimen_note: e.target.value })}>
                                </textarea>
                                {errors.regimen_note && <p className="error">{errors.regimen_note}</p>}
                              </div>
                            </form>
                    <button onClick={handleFormSubmit} type="submit" className="submit-reminder">ذخیره تغییرات</button>
            </div>
        </div>
    );
};

export default EditReminderForm;
