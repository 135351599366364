import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { API } from "../backend";
import { TourCard } from "./TourCard";

export default function Tours() {
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await axios.get(`${API}api/tour/`); // Make sure your endpoint is correct
        setTours(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching tours:", error);
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchTours();
  }, []);

  return (
    <Wrapper className="tours-div" id="tours">
      <div className="lightBg">
        <div className="about-us-div container">
          <HeaderInfo>
            <p className="font40 extraBold">تورهای فعال</p>
          </HeaderInfo>
          <div className="row textCenter">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              tours.length > 0 ? (
                tours.map((tour, index) => (
                  <div key={index} className="col-xs-12 col-sm-4 col-md-4 col-lg-4 mb-4 sm:mb-0">
                    <TourCard key={index} tourData={tour} />
                  </div>
                ))
              ) : (
                <div>تور فعالی نیست!</div>
              )
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 20px;

`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
