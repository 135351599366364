// src/Sidebar.js
import React, { useState } from 'react';
import './Logout.css'; // We'll define some basic styles in this file
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Logout = ({handleClose}) => {
    const navigate = useNavigate();

    const handleLogOut = () => {
      Cookies.remove("token");
      Cookies.remove("role");
      navigate("/");
  }
  
      return (
        <div className="modal-logout display-block">
          <section className="modal-main-logout">
            <div className='all-logout'>
            <p className="logout-text">از خروج از حساب کاربریتان اطمینان دارید؟</p>
            <hr className="logout-divider"></hr>
            <p className="change-text">درصورت خروج تمامی تغییرات ذخیره خواهند شد.</p>

              <div className='logout-ul'>
                  <button 
                    className="logout-yes"
                    onClick={handleLogOut}
                  >
                    بله خارج می شوم
                  </button>

                  <button 
                    className="logout-no"
                    onClick={handleClose}
                  >
                    لغو
                  </button>
              </div>
            </div>
          </section>
        </div>
      );
};

export default Logout;
