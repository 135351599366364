import React from "react";
import styled from "styled-components";
// Assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStethoscope, faDesktop, faTruckMedical, faCircleInfo } from '@fortawesome/free-solid-svg-icons'

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <FontAwesomeIcon className="fa-2xl" icon={ faStethoscope } color="#f2b300" title=""/>;
      break;
    case "monitor":
      getIcon = <FontAwesomeIcon className="fa-2xl" icon={ faTruckMedical } color="#f40051" title=""/>;
      break;
    case "browser":
      getIcon = <FontAwesomeIcon className="fa-2xl" icon={ faDesktop } color="#4cd5c5" title=""/>;
      break;
    case "printer":
      getIcon = <FontAwesomeIcon className="fa-2xl" icon={ faCircleInfo } color="#7620ff" title=""/>;
      break;
    default:
      getIcon = <FontAwesomeIcon className="fa-2xl" icon={ faStethoscope } color="#f2b300" title=""/>;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle className="title-services-icon">{getIcon}</IconStyle>
      <TitleStyle className="title-services font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="services-text font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
`;