import React, { useState, useRef, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { signup, signin, verify }  from '../apis/api';
import "./Signin.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBookOpenReader, faFilterCircleXmark, faXmark } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie';

const Signin = ({ handleClose, handleShowSignup  }) => {

  const [signinOrVerify, setSigninOrVerify] = useState(1);
  const [isNumberExists, setIsNumberExists] = useState(true);
  const [isOTPRight, setIsOTPRight] = useState(true);

  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/profile');
  };

  const [values, setValues] = useState({
    mobile: "",
  });

  const { mobile } = values;


  // const handleChangeOtp = (otp) =>
  //   (event) => {
  //     setValuesOtp({ ...valuesOtp, [otp]: event.target.value });
  //   };

  const handleChange = (mobile) =>
    (event) => {
      setValues({ ...values, [mobile]: event.target.value });
    };

  const [token, setToken] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values});
    signin({ mobile })
      .then((data) => {
        console.log(data)
          if(data.message == "There is no user with this phone number"){
            setIsNumberExists(false);
            setRemainingTime(0);

          }
          else if(data.message == "OTP was sent"){
            setIsNumberExists(true);
            setShowOtp(true);
            setRemainingTime(120);
            setResendDisabled(true);
          }
          setSigninOrVerify(2);
      })
      .catch((e) => console.log(e));
  };


  const onSubmitVerify = (event) => {
    event.preventDefault();
    // setValuesOtp({ ...valuesOtp});
    verify( {mobile} , {otp} )
      .then((data) => {
        if(data.message == "OTP is wrong"){
          setIsOTPRight(false);
        }
        else{
          setIsOTPRight(true);
          setToken(data.token);
          Cookies.set('token', data.token, { expires: 365 });
          Cookies.set('role', data.user_type, { expires: 365 });
          Cookies.set('userType', data.user_type, { expires: 365 });
          navigateHome();
        }

      })
      .catch((e) => console.log(e));
  };


  const [otp, setOTP] = useState('');
  const inputRefs = useRef([]);

  const handleInput = (event, index) => {
    const value = event.target.value;
    if (value.length === 1 && index < 3) {
      inputRefs.current[index + 1].focus();
    }
    setOTP((prev) => prev.substring(0, index) + value + prev.substring(index + 1));
  };

  const handleKeyDown = (event, index) => {
    if (event.keyCode === 8 && !otp[index]) {
      event.preventDefault();
      if (index > 0) {
        inputRefs.current[index - 1].focus();
        setOTP((prev) => prev.substring(0, index - 1) + prev.substring(index));
      } else {
        setOTP('');
        inputRefs.current[0].focus();
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain');
    setOTP(pasteData.substr(0, 4));
  };

  const [remainingTime, setRemainingTime] = useState(0);
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    // Decrease the remaining time every second
    const interval = setInterval(() => {
      if(remainingTime != 0){
        setRemainingTime((prev) => prev - 1);
      }
    }, 1000);

    // Enable the button when the timer is over
    if (remainingTime === 0) {
      setResendDisabled(false);
    }

    // Clean up the interval
    return () => clearInterval(interval);
  }, [remainingTime]);



  const isDisabled = mobile === '' || mobile.length !== 11;
  const allFieldsFilled = otp.length == 4;


  return (
    <div className={`modal-add-event display-block`}>
    <section className="modal-main-add-event">
      <div className='xmark-font-awsome-right'>
          <button className='submit-filter-but'>
            <FontAwesomeIcon onClick={handleClose} className="fontawesome-icon fa-2xl" icon={ faXmark } color="#DE3163"/> 
        </button>     
      </div>
      <div className={`hidden-div`}>
      <div className="sigin-verify-div">
      <div className="flex-container-signin">
        <div className="flex-child-signin">
          {!isNumberExists && <div className="no-number">
                    <p className="no-number">
                      شماره مورد نظر پیدا نشد.
                    </p>
                  </div>}
          <div className='input-group'>
                        <label className="input-labels" for="name-signin">شماره تلفن</label>
                        <input className="inputs"
                                    id="name-signin"
                                    value={mobile}
                                    maxLength={11}
                                    onChange={handleChange("mobile")}
                                    type="text"
                                    >
                        </input>
          </div>
          
        </div>
        <div className="flex-child-signin">
          <button
                      onClick={onSubmit}
                      className={`send-code-button ${!isDisabled && !resendDisabled && remainingTime == 0 ? 'enabled' : 'disabled'}`}
                      disabled={isDisabled}
                    >
                      ارسال کد
          </button>
          {(resendDisabled || remainingTime > 0) && isNumberExists && 
          <div className="send-again1">
            <p className="send-again1">
                        {`ارسال مجدد (${remainingTime} ثانیه)`}
            </p>
          </div>}
        </div>
        <div className={showOtp ? "flex-child-signin" : "flex-child-signin hidden"}>
        <form className="form-otp">
                {[...Array(4)].map((_, index) => (
                  <input
                    className="form-control"
                    id="otp-code"
                    key={index}
                    type="text"
                    maxLength="1"
                    inputMode="numeric"
                    value={otp[index] || ''}
                    onChange={(event) => handleInput(event, index)}
                    onKeyDown={(event) => handleKeyDown(event, index)}
                    onPaste={handlePaste}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </form>
        {!isOTPRight && <div className="no-code">
                    <p className="no-code">
                    کد وارده اشتباه می باشد، دوباره سعی نمایید
                    </p>
        </div>}
        </div>
        <button 
                  onClick={onSubmitVerify}
                  className={`submit-verification-code ${allFieldsFilled ? 'enabled' : 'disabled'}`}
                  disabled={!allFieldsFilled}
                >
                  ورود
        </button>
      </div>
      </div>
      </div>
        <div className="switch-auth-container">
          <p>حساب کاربری ندارید؟</p>
          <button onClick={handleShowSignup} className="switch-auth-button">ثبت‌نام</button>
        </div>
      </section>
    </div>
  );
};

export default Signin;