import React from 'react';
import './DeleteConfirmation.css'; // Create a similar CSS file for styles

const DeleteConfirmation = ({ handleConfirm, handleCancel }) => {
    return (
        <div className="modal-delete display-block">
            <section className="modal-main-delete">
                <div className='all-delete'>
                    <p className="delete-text">آیا از حذف این مورد اطمینان دارید؟</p>
                    <hr className="delete-divider"></hr>
                    <p className="warning-text">پس از حذف این عملیات بازگردانی نمی‌شود.</p>

                    <div className='delete-buttons'>
                        <button
                            className="delete-confirm"
                            onClick={handleConfirm}
                        >
                            بله، حذف شود
                        </button>

                        <button
                            className="delete-cancel"
                            onClick={handleCancel}
                        >
                            لغو
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DeleteConfirmation;
