import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import Signup from "../../screens/Signup";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import Signin from "../../screens/Signin";
import Cookies from "js-cookie";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logout from "../../screens/Logout";

export default function TopNavbar() {
  const { t } = useTranslation();
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/profile');
  };
  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const [showLogin, setShowLogin] = useState(false);
  const [showSignin, setShowSignin] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  const handleShowLogin = () => {
    setShowSignin(false);
    setShowLogin(true);
  };

  const handleHideLogin = () => {
      setShowLogin(false);
  };

  const handleShowSignin = () => {
    setShowSignin(true);
  };

  const handleHideSignin = () => {
      setShowSignin(false);
  };

  const handleShowLogout = () => {
      setShowLogout(true);
  };

  const handleHideLogout = () => {
      setShowLogout(false);
  };


  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px", borderBottom: "1px solid black" } : { height: "80px", borderBottom: "1px solid black" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="teaser" smooth={true}>
            <LogoIcon />
            <h1 style={{ marginLeft: "15px" }} className="green-text font20 extraBold">
            <p>{t('CompanyName')}</p>
            </h1>
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          {/* <LanguageSwitcher /> */}
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link activeClass="active" className="green-text" style={{ padding: "10px 15px" }} to="blog" spy={true} smooth={true} offset={-80}>
              {t('NavbarBlog')}
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" className="green-text" style={{ padding: "10px 15px" }} to="locations" spy={true} smooth={true} offset={-80}>
              {t('NavbarAbout')}
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" className="green-text" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
              {t('NavbarPeople')}
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" className="green-text" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
              {t('NavbarService')}
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" className="green-text" style={{ padding: "10px 15px" }} to="teaser" spy={true} smooth={true} offset={-80}>
              {t('NavbarHome')}
              </Link>
            </li>
            {/* <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                Contact
              </Link>
            </li> */}
          </UlWrapper>
          {!Cookies.get('token') && <UlWrapperRight className="flexNullCenter">
            <li onClick={handleShowLogin} className="semiBold font15 pointer">
              <a className="green-text" style={{ padding: "10px 30px 10px 0" }}>
              {t('SignUp')}
              </a>
            </li>
            <li onClick={handleShowSignin} className="semiBold font15 pointer flexCenter">
              <a className="green-text radius8 lightBg" style={{ padding: "10px 15px" }}>
              {t('Start')}

              </a>
            </li>
          </UlWrapperRight>}
          {Cookies.get('token') && <UlWrapperRight className="flexNullCenter">
            <li onClick={navigateHome} className="semiBold font15 pointer flexCenter">
              <a className="green-text radius8 lightBg" style={{ padding: "10px 15px" }}>
              <FontAwesomeIcon icon={ faUserAlt }/>
              </a>
            </li>
          </UlWrapperRight>}
        </NavInner>
      </Wrapper>
      {showLogin && <Signup handleClose={handleHideLogin}/>}
      {showSignin && <Signin handleClose={handleHideSignin} handleShowSignup={handleShowLogin}/>}
      {showLogout && <Logout handleClose={handleHideLogout} />}
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


