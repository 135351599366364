import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { API } from "../backend"; // Adjust this based on your API configuration
import Confetti from "./Confetti"; // Import the Confetti component
import ConfettiReserve from "./ConfettiReserve";

export default function TourModal({ tourData, onClose }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    gender: '',
    age: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [showConfetti, setShowConfetti] = useState(false); // State to handle confetti display

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${API}api/formreserve/`, {
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone_number: formData.phoneNumber,
        gender: formData.gender,
        age: formData.age,
        tour: tourData.id  // Linking the reservation to the specific tour
      });
      setSuccessMessage('رزرو شما با موفقیت انجام شد!');
      setShowConfetti(true); // Show confetti on successful reservation
      setFormData({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        gender: '',
        age: ''
      });
    } catch (error) {
      console.error('Error creating reservation:', error);
      alert('ایجاد رزرو با مشکل مواجه شد. لطفا دوباره تلاش کنید.');
    }
  };

  const isFormComplete = Object.values(formData).every(field => field !== '');

  const coverImage = tourData.images && tourData.images.length > 0 ? tourData.images[0].image : '';

  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={onClose}>×</CloseButton>
        {coverImage && (
          <CoverImageWrapper>
            <CoverImage src={coverImage} alt="Tour cover" />
          </CoverImageWrapper>
        )}
        <StyledTitle>{tourData.title}</StyledTitle> {/* Updated Title */}
        <StyledDescription>{tourData.description}</StyledDescription> {/* Updated Description */}

        {/* Reservation Form */}
        <form onSubmit={handleSubmit}>
          <StyledInput
            type="text"
            name="firstName"
            placeholder="نام"
            value={formData.firstName}
            onChange={handleChange}
          />
          <StyledInput
            type="text"
            name="lastName"
            placeholder="نام خانوادگی"
            value={formData.lastName}
            onChange={handleChange}
          />
          <StyledInput
            type="text"
            name="phoneNumber"
            placeholder="شماره تماس"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <StyledSelect
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="">جنسیت</option>
            <option value="male">مرد</option>
            <option value="female">زن</option>
          </StyledSelect>
          <StyledInput
            type="number"
            name="age"
            placeholder="سن"
            value={formData.age}
            onChange={handleChange}
          />
          <SubmitButton type="submit" disabled={!isFormComplete}>رزرو</SubmitButton>
        </form>

        {/* {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>} */}
      </ModalContent>

      {/* Conditionally render the confetti */}
      {showConfetti && <ConfettiReserve handleClose={() => setShowConfetti(false)} handleShowLogin={() => window.location.reload(false)}/>}
    </ModalWrapper>
  );
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh; /* Set maximum height */
  overflow-y: auto; /* Allow scrolling */
  position: relative;
  text-align: center;

  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;


const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const CoverImageWrapper = styled.div`
  margin-bottom: 20px;
`;

const CoverImage = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const StyledSelect = styled.select`
  display: block;
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const SubmitButton = styled.button`
  background: ${props => props.disabled ? '#cccccc' : '#7620ff'};
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-size: 16px;
  margin-top: 10px;
  transition: background 0.3s;

  &:hover {
    background: ${props => props.disabled ? '#cccccc' : '#0056b3'};
  }
`;

const StyledTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  background: linear-gradient(to right, #007bff, #00d4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
`;

const StyledDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
  line-height: 1.6;
  text-align: justify;
  border-left: 4px solid #007bff;
  padding-left: 10px;
  margin-left: 15px;
  background-color: rgba(240, 248, 255, 0.6);
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

