// RecentBlogCard.tsx

import React from "react";
import { useNavigate } from "react-router-dom";

interface RecentBlogCardProps {
  blogData: {
    id: string;
    slug: string;
    cover_image: string;
    title: string;
    author_full_name: string;
    author_profile_image: string;
    content: string;
  };
}

const RecentBlogCard: React.FC<RecentBlogCardProps> = ({ blogData }) => {
  const navigate = useNavigate();

  const viewBlogOnClick = () => {
    navigate(`/blog/view/${blogData.id}/${blogData.slug}`);
  };

  const removeImagesFromContent = (content: string): string => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    Array.from(tempDiv.getElementsByTagName("img")).forEach((img) => img.remove());

    let text = tempDiv.textContent || tempDiv.innerText || "";
    return text.length > 150 ? text.substring(0, 150) + "..." : text;
  };

  return (
    <div
      onClick={viewBlogOnClick}
      className="bg-gray-100 rounded-lg shadow-md hover:shadow-lg transition-all cursor-pointer overflow-hidden"
    >
      <img src={blogData.cover_image} alt="Blog Cover" className="w-full h-40 object-cover" />
      <div className="p-4">
        <h3 className="font-bold text-md mb-2 truncate">{blogData.title}</h3>
        <div className="flex items-center my-2">
          <img
            src={blogData.author_profile_image}
            alt="Author"
            className="w-6 h-6 rounded-full"
          />
          <p className="ml-2 text-sm">{blogData.author_full_name}</p>
        </div>
        <p className="text-xs text-gray-600 line-clamp-3">
          {removeImagesFromContent(blogData.content)}
        </p>
      </div>
    </div>
  );
};

export default RecentBlogCard;
