import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import i18n from '../../i18n'; // Make sure you import the i18n instance
import { ErrorMessage } from "../../components/ErrorMessage";
import { useGetAllBlogsQuery } from "../../hooks/blogs/useGetAllBlogsQuery";
import { BlogCard } from "../../pages/viewallblogs/components/BlogCard";
import { LandingPageBlogCard } from "./LandingPageBlogCard";

export default function Blog() {
  const { t } = useTranslation();
  const directionClass = i18n.language === 'en' ? 'ltr-i18n' : '';

  const navigate = useNavigate();
  const navigateBlog = () => {
    navigate('/blog/');
  };

  // Fetch the latest blogs (limit to 3)
  const { data: blogs, isError, isLoading } = useGetAllBlogsQuery('all', 1);


  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <Wrapper className="blog-div" id="blog">
      <div className="whiteBg">
        <div className={`container ${directionClass}`}>
          <HeaderInfo>
            <p className="font40 extraBold">{t('BlogTitle')}</p>
          </HeaderInfo>
          <div className="row textCenter">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              blogs?.results && blogs.results.result.length > 0 ? (
                // blogs.results.result.map((blog, index) => (
                blogs.results.result.slice(0, 3).map((blog, index) => (
                  <div key={index} className="col-xs-12 col-sm-4 col-md-4 col-lg-4 mb-4 sm:mb-0">
                    {/* Use the BlogCard component for rendering each blog */}
                    {/* <BlogCard blogData={blog} /> */}
                    <LandingPageBlogCard key={index} blogData={blog} />
                  </div>
                ))
              ) : (
                <div>No blogs available</div>
              )
            )}
          </div>
          <div className="row flexCenter">
            <div onClick={navigateBlog} style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title={t('BlogMore')} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

