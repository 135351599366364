import React, { useEffect, useState } from 'react';
import './packaging.css';
import { getAllResidences, getAllHealthCentersForProvince, getAllDoctorsForHealthCenters } from '../apis/api';
import { LuPackageSearch } from "react-icons/lu";
import { FaUserDoctor, FaMountainCity  } from "react-icons/fa6";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import { Chrono } from "react-chrono";
import dayjs, { Dayjs } from 'dayjs';
import jalaliMoment from 'jalali-moment';

import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; // Make sure you import the i18n instance

const Packaging = ({ handleClose }) => {
    const { t } = useTranslation();

    const convertToJalali = (gregorianDate) => {
        const date = new Date(gregorianDate);
        return jalaliMoment(date, 'YYYY/MM/DD').locale('fa').format('YYYY-MM-DD');
    };

    const useWindowWidth = () => {
        const [width, setWidth] = useState(window.innerWidth);
      
        useEffect(() => {
          const handleResize = () => {
            setWidth(window.innerWidth);
          };
      
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);
      
        return width;
      };
    const width = useWindowWidth();
    const [error, setError] = useState('')

    const [residences, setResidences] = useState([])
    const [residencesLoaded , setResidencesLoaded] = useState(false);

    const [healthCenters, setHealthCenters] = useState([])
    const [healthCentersLoaded , setHealthCentersLoaded] = useState(false);

    const [doctors, setDoctors] = useState([])
    const [doctorsLoaded , setDoctorsLoaded] = useState(false);

    const [provinceId, setProvinceId] = useState('');
    const [healthCenterId, setHealthCenterId] = useState('');

    const [whichLevel, setWhichLevel] = useState(0);

    const [residenceId, setResidenceId] = useState('');
    const [selectedResidence, setSelectedResidence] = useState([]);
    const [healthId, setHealthId] = useState('');
    const [selectedHealthCenter, setSelectedHealthCenter] = useState([]);
    const [doctorId, setDoctorId] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState([]);
    const directionClass = i18n.language === 'en' ? 'ltr-i18n' : '';


    const loadAllResidences = () => {
        getAllResidences()
        .then((data) => {
            if (data.error) {
            setError(data.error);
            console.log(error);
            } else {
                setResidences(data);
                setResidencesLoaded(true)
            }
        });
    };

    const loadAllHealthCenters = () => {
        getAllHealthCentersForProvince(provinceId)
          .then((data) => {
            if (data.error) {
              setError(data.error);
              console.log(error);
            } else {
                setHealthCenters(data);
                setHealthCentersLoaded(true)
            }
          });
      };

      const loadAllDoctors = () => {
        getAllDoctorsForHealthCenters(healthCenterId)
          .then((data) => {
            if (data.error) {
              setError(data.error);
              console.log(error);
            } else {
                setDoctors(data);
                setDoctorsLoaded(true)
            }
          });
      };

    useEffect(() => {
        loadAllResidences();
    }, [])

    useEffect(() => {
        if(provinceId){
          loadAllHealthCenters();
        }
      }, [provinceId])

    useEffect(() => {
        if(healthCenterId){
          loadAllDoctors();
        }
      }, [healthCenterId])

    const handleChangeLevel = () => {
        setWhichLevel(1);
    }

    const handleChangeLevelToZero = () => {
        setWhichLevel(0);
    }
    const [timelineItems, setTimelineItems] = useState([]); 
    useEffect(() => {
        if (selectedHealthCenter.length != 0) {
            const destFlyDateJalali = convertToJalali(selectedResidence.to_dest_fly_date);
            const originFlyDateJalali = convertToJalali(selectedResidence.to_origin_fly_date);
            const startResidenceDateJalali = convertToJalali(selectedResidence.residence_start_date);
            const endResidenceDateJalali = convertToJalali(selectedResidence.residence_end_date);
            const operationDateJalali = convertToJalali(selectedHealthCenter.operation_date);
            const startAfterOperationDateJalali = convertToJalali(selectedHealthCenter.after_operation_start_date);
            const endAfterOperationDateJalali = convertToJalali(selectedHealthCenter.after_operation_end_date);
            const items = [
                {
                    title: destFlyDateJalali,
                    cardTitle: `${t('PackageFlightSTD')}`,
                },
                {
                    title: `${endResidenceDateJalali} تا ${startResidenceDateJalali}`,
                    cardTitle: `${t('PackageFun')}`,
                },
                {
                    title: operationDateJalali,
                    cardTitle: `${t('PackageOperationDate')}`,
                },
                {
                    title: `${endAfterOperationDateJalali} ${t('PackageTo')} ${startAfterOperationDateJalali}`,
                    cardTitle: `${t('PackageOperationCare')}`,
                },
                {
                    title: originFlyDateJalali,
                    cardTitle: `${t('PackageFlightDTS')}`,
                },
            ];
            setTimelineItems(items);
        }
    }, [selectedHealthCenter]);

    return (
        <div className={`modal-packaging display-block`} onClick={handleClose}>
            <section className={whichLevel == 0 ? "modal-main-packaging" : "modal-main-packaging-result"} onClick={(e) => e.stopPropagation()}>
                {whichLevel == 0 && <div>
                    <div className='package-desc-div'>
                        <p className='package-desc'>
                        {t('PackageDesc')}
                        </p>
                        <LuPackageSearch color='#DE3163' className='react-icons-comment-warning'/>
                    </div>
                    <div className='items-list-div'>
                        <ul className='items-list-ul'>
                            <li>
                                <label className='select-label-figma'>{t('PackageTourism')}</label>
                                <br />
                                    <select 
                                        className="filter-elements"
                                                value={residenceId}
                                                onChange={(e) => {
                                                const selectedId = e.target.value;
                                                const selectedItem = residences.find(item => item.id === selectedId);
                                                setResidenceId(selectedId);
                                                setProvinceId(selectedItem.province.id)
                                                setSelectedResidence(selectedItem);
                                                setHealthId("")
                                                setDoctorId("")
                                                }}
                                                type="text"
                                                disabled={residences.length == 0 ? true : false}
                                                >
                                                {residences.length != 0 && <option value="" disabled selected hidden>{t('PackageSelect')}</option>}
                                                {residences.length == 0 && <option value="" disabled selected hidden>{t('PackageSelectNone')}</option>}
                                                {residences.length != 0 && residences.map(item => {
                                                    return (<option key={item.id} value={item.id}>{item.name}</option>);
                                                })}
                                    </select>
                            </li>

                            {residenceId && <li>
                            <label className='select-label-figma'>{t('PackageHealth')}</label>
                            <br />
                            <select 
                                    className="filter-elements"
                                            value={healthCenterId}
                                            onChange={(e) => {
                                                const selectedHealthId = e.target.value;
                                                const selectedHealthItem = healthCenters.find(item => item.id === selectedHealthId);
                                                setHealthCenterId(selectedHealthId);
                                                setSelectedHealthCenter(selectedHealthItem);
                                                setDoctorId("")
                                            }}
                                            type="text"
                                            disabled={healthCenters.length == 0 ? true : false}
                                            >
                                            {healthCenters.length != 0 && <option value="" disabled selected hidden>{t('PackageSelect')}</option>}
                                            {healthCenters.length == 0 && <option value="" disabled selected hidden>{t('PackageSelectNone')}</option>}
                                            {healthCenters.length != 0 && healthCenters.map(item => {
                                                return (<option key={item.id} value={item.id}>{item.name}</option>);
                                            })}
                            </select>
                            </li>}

                            {healthCenterId && <li>
                            <label className='select-label-figma'>{t('PackageDoctor')}</label>
                            <br />
                            <select 
                                    className="filter-elements"
                                            value={doctorId}
                                            onChange={(e) => {
                                                const selectedDoctorId = e.target.value;
                                                const selectedDoctorItem = doctors.find(item => item.id === selectedDoctorId);
                                                setDoctorId(selectedDoctorId);
                                                setSelectedDoctor(selectedDoctorItem);
                                            }}
                                            type="text"
                                            disabled={doctors.length == 0 ? true : false}
                                            >
                                            {doctors.length != 0 && <option value="" disabled selected hidden>{t('PackageDoctorSelect')}</option>}
                                            {doctors.length == 0 && <option value="" disabled selected hidden>{t('PackageSelectNone')}</option>}
                                            {doctors.length != 0 && doctors.map(item => {
                                                return (<option key={item.id} value={item.id}>{item.full_name}</option>);
                                            })}
                            </select>
                            </li>}
                            </ul>
                        </div>
                <button disabled={!doctorId} className={doctorId ? 'add-event-button' : 'add-event-button-disabled'} onClick={handleChangeLevel}>{t('PackageMake')}</button>
                </div>}
                {whichLevel == 1 && <div>
                    <div
                        className="CityImage"
                        style={{ backgroundImage: `url(${selectedResidence.image})` }}
                        >
                        {width >= 500 && <img className="DoctorImage" src={selectedDoctor.image} alt="Doctor" />}
                    </div>
                    <div className="Details">
                        <p className="Info">{selectedDoctor.full_name} : <FaUserDoctor /></p>
                        <p className="Info">{(selectedDoctor.cost + selectedResidence.cost + selectedHealthCenter.cost).toLocaleString()} : ({t('PackageToman')}) <FaMoneyCheckAlt /></p>
                        <p className="Info">{selectedResidence.province.title} : <FaMountainCity /></p>
                        <p className="Info">{(new Date(selectedResidence.to_origin_fly_date) - new Date(selectedResidence.to_dest_fly_date)) / (1000 * 60 * 60 * 24)} : ({t('PackageDay')}) <GiDuration /></p>
                        {/* <p className="Info">Dates: {dates}</p> */}
                    </div>
                </div>}
                {whichLevel == 1 && selectedHealthCenter.length != 0 && <div>
                    
                    {width >= 500 && <div className="timelines">
                        <Chrono
                            items={timelineItems}
                            mode="VERTICAL"
                            // showAllCardsHorizontal={true}
                            itemWidth={125}
                            cardWidth={75}
                            cardHeight={50}
                            disableToolbar={true}
                            // useReadMore={true}
                            responsiveBreakPoint={500}
                            fontSizes={{
                                cardText: '10px',
                                cardTitle: '10px',
                                title: '10px',
                              }}
                        />
                    </div>}
                    {width <= 500 && <div className="timelines">
                        <Chrono
                            items={timelineItems}
                            mode="VERTICAL"
                            // showAllCardsHorizontal={true}
                            itemWidth={125}
                            cardWidth={150}
                            cardHeight={50}
                            disableToolbar={true}
                            // useReadMore={true}
                            responsiveBreakPoint={500}
                            fontSizes={{
                                cardText: '10px',
                                cardTitle: '10px',
                                title: '10px',
                              }}
                        />
                    </div>}
                </div>}

                {whichLevel == 1 && <div>
                    <button className={'thanks-button-package'} onClick={handleChangeLevelToZero}>{t('PackageMakeNew')}</button>
                </div>}
            </section>
        </div>
    );
};

export default Packaging;
