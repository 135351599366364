import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import i18n from '../../i18n'; // Make sure you import the i18n instance
import { ErrorMessage } from "../ErrorMessage";
import { useGetAllBlogsQuery } from "../../hooks/blogs/useGetAllBlogsQuery";
import { BlogCard } from "../../pages/viewallblogs/components/BlogCard";

export const LandingPageBlogCard = ({ blogData }: any): JSX.Element => {
  const navigate = useNavigate();

  const viewBlogOnClick = () => {
    navigate(`/blog/view/${blogData.id}/${blogData.slug}`);
  };

  const removeImagesFromContent = (content: string): string => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    const images = tempDiv.getElementsByTagName('img');
    Array.from(images).forEach((image) => {
      image.remove();
    });

    let text = tempDiv.textContent || tempDiv.innerText;
    text = text.trim();

    if (text.length > 200) {
      text = text.substring(0, 200) + '...';
    }

    return text;
  };

  return (
    <div
      onClick={viewBlogOnClick}
      className="bg-white rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform duration-300 cursor-pointer"
      style={{ height: '100%' }}  // Ensure card takes full height
    >
      {/* Cover image */}
      <img
        src={blogData["cover_image"]}
        alt="cover-image"
        className="w-full h-48 object-cover rounded-t-lg"
      />

      {/* Blog details */}
      <div className="p-4 flex flex-col flex-grow">
        {/* Title */}
        <h3 className="mb-4 font-bold text-sm text-gray-800 overflow-hidden whitespace-nowrap text-ellipsis">{blogData.title}</h3>

        {/* Author info */}
        <div className="mb-4 flex items-center my-2">
          <img
            src={blogData["author_profile_image"]}
            alt="author-profile"
            className="w-8 h-8 rounded-full"
          />
          <p className="ml-2 text-sm text-gray-600">{blogData["author_full_name"]}</p>
        </div>

        {/* Blog snippet */}
        <p className="text-sm text-gray-600 text-justify">
          {removeImagesFromContent(blogData?.content || '')}
        </p>
      </div>
    </div>
  );
};

  